const library = {

    /* A R R A Y */

    guides: [
        { id: 1, fontSize: 1.00, lineHeight: 1.30, rowsMax: 4 },
        { id: 2, fontSize: 0.81, lineHeight: 1.25, rowsMax: 5 },
        { id: 3, fontSize: 0.70, lineHeight: 1.20, rowsMax: 6 },
        { id: 4, fontSize: 0.59, lineHeight: 1.20, rowsMax: 7 },
        { id: 5, fontSize: 0.52, lineHeight: 1.20, rowsMax: 8 },
        { id: 6, fontSize: 0.46, lineHeight: 1.20, rowsMax: 9 },
        { id: 7, fontSize: 0.40, lineHeight: 1.20, rowsMax: 10 },
        { id: 8, fontSize: 0.37, lineHeight: 1.20, rowsMax: 11 },
        { id: 9, fontSize: 0.33, lineHeight: 1.20, rowsMax: 12 },
        { id: 10, fontSize: 0.31, lineHeight: 1.20, rowsMax: 13 },
        { id: 11, fontSize: 0.30, lineHeight: 1.20, rowsMax: 14 },
    ],

    output: {
        fontSize: 0.15, // size relative to canvas width
    },

    /* F U N C T I O N */

    debounce: function(func, timeout) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        }
    },

}

export default library;