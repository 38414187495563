/* L I B R A R Y */

/* Module */
import React, { PureComponent } from 'react';

/* Component */
import Debug from './Debug';
import Editor from './Editor';

/* C O M P O N E N T */

export default class App extends PureComponent {

    /* Constructor */
    constructor(props) {
        super(props);
        // state
        this.state = {
            output: {
                // content
                value: '',
                placeholder: 'Text here',
                // type
                fontSize: 0,
                lineHeight: 0,
                rows: 0,
                // design
                backgroundColor: '#ffdb0e',
            },
        }
    }

    /* f: App */
    setAppState = (state, properties) => {
        this.setState(prevState => ({
            [state]: {
                ...prevState[state],
                ...properties,
            },
        }));
    }

    /* Render */
    render() {
        const { output } = this.state;
        return (
            <div className="app">

                <Debug
                    output={output}
                />

                <Editor
                    action={{
                        setAppState: this.setAppState,                      
                    }}
                    output={output}
                />

            </div>
        );
    }

}