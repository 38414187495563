/* L I B R A R Y */

/* Module */
import React from 'react';

/* C O M P O N E N T */

export default function EditorMenu() {
    return(
        <div className="editor-menu">

        </div>
    );

}