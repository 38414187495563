/* L I B R A R Y */

/* Module */
import React, { PureComponent } from 'react';

/* Component */
import library from './Library';

/* C O M P O N E N T */

export default class EditorForm extends PureComponent {

    /* Constructor */
    constructor(props) {
        super(props);
        // bind
        this.onResize = this.onResize.bind(this);
        // ref
        this.input = React.createRef();
        library.guides.map(guide => {
            return this['guide' + guide.id] = React.createRef();
        });
        // state
        this.state = {
            form: {
                width: 0,
                height: 0,
            },
            formInput: {
                fontSize: 0,
                lineHeight: 0,
                rows: 0,
            },
            formGuides: [],
        }
    }

    /* f: General */
    componentDidMount() {
        this.formInput.focus();
        this.measureForm();
        window.addEventListener('resize', this.onResize, false);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize, false);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.output !== this.props.output) {
            this.scaleFormInput();
        } if (prevProps.output.value !== this.props.output.value) {
            this.scaleOutput();
        } if (prevState.form.width !== this.state.form.width) {
            this.scaleFormGuides();
        } if (prevState.formGuides !== this.state.formGuides) {
            this.scaleOutput();
        }
    }
    onResize = library.debounce(() => {
        this.measureForm();
    }, 0);

    /* f: Form */
    measureForm = () => {
        const width = this.form.offsetWidth;
        const height = this.form.offsetHeight;
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                width: width,
                height: height,
            },
        }));
    }

    /* f: FormInput */
    scaleFormInput = () => {
        const { output } = this.props;
        const { form } = this.state;
        const fontSize = form.width * output.fontSize * library.output.fontSize;
        const lineHeight = fontSize * output.lineHeight;
        this.setState(prevState => ({
            formInput: {
                ...prevState.formInput,
                fontSize: fontSize,
                lineHeight: lineHeight,
                rows: output.rows,
            },
        }));
    }

    /* f: FormGuides */
    scaleFormGuides = () => {
        const { form } = this.state;
        const guides = [];
        library.guides.map(guide => {
            guide = { ...guide };
            guide.fontSize = form.width * guide.fontSize * library.output.fontSize;
            guide.lineHeight = guide.fontSize * guide.lineHeight;
            return guides.push(guide);
        });
        this.setState({ formGuides: guides });
    }

    /* f: Output */
    scaleOutput = () => {
        // measure formGuides rows
        const guides = [];
        this.state.formGuides.map((guide, index) => {
            const elem = this['guide' + guide.id];
            elem.style.height = 'auto';
            const scrollHeight = elem.scrollHeight;
            elem.style.height = scrollHeight + 'px';
            let rows = Math.round(scrollHeight / guide.lineHeight);
            if (rows <= 0) { rows = 1 }
            return guides.push({
                fontSize: library.guides[index].fontSize,
                lineHeight: library.guides[index].lineHeight,
                rows: rows,
                rowsMax: library.guides[index].rowsMax,
            })
        });
        // apply values to output
        const { action } = this.props;
        let output = {};
        guides.slice(0).reverse().map((guide, index) =>
            (guide.rows <= guide.rowsMax || index === 0) ?
            output = { ...guide } :
            null
        );
        action.setAppState('output', {
            fontSize: output.fontSize,
            lineHeight: output.lineHeight,
            rows: output.rows,
        });
    }

    /* Render */
    render() {
        const { action, output } = this.props;
        const { formInput, formGuides } = this.state;
        return(
            <form
                // base
                ref={node => { this.form = node }}
                className="editor-form"
                // style
                style={{
                    backgroundColor: output.backgroundColor,
                }}
            >
                
                {/* Input */}
                <textarea
                    // base
                    ref={node => { this.formInput = node }}
                    value={output.value}
                    placeholder={output.placeholder}
                    // complimentary
                    autoComplete="off"
                    rows={formInput.rows}
                    spellCheck="false"
                    // function
                    onChange={event => {
                        action.setAppState('output', { value: event.target.value })
                    }}
                    // style
                    style={{
                        fontSize: formInput.fontSize + 'px',
                        lineHeight: formInput.lineHeight + 'px',
                        zIndex: 10,
                    }}
                />

                {/* Guides */}
                {formGuides.map(guide =>
                    <textarea
                        // base
                        key={guide.id.toString()}
                        ref={node => { this['guide' + guide.id] = node }}
                        value={output.value}
                        placeholder={output.placeholder}
                        // complimentary
                        autoComplete="off"
                        disabled={true}
                        rows="1"
                        spellCheck="false"
                        // function
                        onChange={event => { event.preventDefault() }}
                        // style
                        style={{
                            fontSize: guide.fontSize + 'px',
                            lineHeight: guide.lineHeight + 'px',
                            opacity: 0,
                        }}
                    />
                )}

            </form>
        )
    }

}