/* L I B R A R Y */

/* Module */
import React from 'react';

/* Component */
import EditorForm from './EditorForm';
import EditorMenu from './EditorMenu';

/* Style */
import '../style/editor.css';

/* C O M P O N E N T */

export default function Editor(props) {
    const { action, output } = props;
    return(
        <div className="editor">
            <EditorForm
                action={action}
                output={output}
            />
            <EditorMenu />
        </div>
    );

}