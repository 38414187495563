/* L I B R A R Y */

/* Module */
import React from 'react';

/* Style */
import '../style/debug.css';

/* C O M P O N E N T */

export default function Debug(props) {
    const { output } = props;
    return(
        <ul className="debug">
            <li>
                <p className="title">output</p>
                <p>fontSize: {output.fontSize}</p>
                <p>lineHeight: {output.lineHeight}</p>
                <p>rows: {output.rows}</p>
            </li>
        </ul>
    );

}